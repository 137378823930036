import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TradingViewPage from "./pages/TradingViewPage";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <TradingViewPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
