import React from "react";
import { useLocation } from "react-router-dom";
import { TradingViewChart } from "components";

const TradingViewPage: React.FC = () => {
  const { search } = useLocation();
  const searchQueries = search
    .substring(1)
    .split("&")
    .map((str) => str.split("="));

  const pairAddress = searchQueries.find((query) => query[0] === "pairAddress");
  const pairName = searchQueries.find((query) => query[0] === "pairName");
  const tokenReversed = searchQueries.find(
    (query) => query[0] === "tokenReversed"
  );

  return pairAddress &&
    pairAddress.length === 2 &&
    pairName &&
    pairName.length === 2 ? (
    <TradingViewChart
      pairAddress={pairAddress[1]}
      pairName={pairName[1]}
      pairTokenReversed={
        tokenReversed &&
        tokenReversed.length === 2 &&
        tokenReversed[1] === "true"
      }
    />
  ) : (
    <>
      You need to add pairAddress, pairName and pairTokenReversed parameters to
      the url.
    </>
  );
};

export default TradingViewPage;
